import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";
import Masonry from "react-masonry-component";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";

//images
// import masonary1 from "./../../images/gallery/fieldTrip/Picture25.png";
import masonary2 from "./../../images/gallery/fieldTrip/Picture26.png";
// import masonary3 from "./../../images/gallery/fieldTrip/Picture27.png";
import masonary4 from "./../../images/gallery/fieldTrip/Picture28.png";
import masonary5 from "./../../images/gallery/fieldTrip/Picture29.png";
// import masonary6 from "./../../images/gallery/fieldTrip/Picture30.png";
import masonary7 from "./../../images/gallery/fieldTrip/Picture31.png";
import masonary8 from "./../../images/gallery/fieldTrip/Picture32.png";
import masonary9 from "./../../images/gallery/fieldTrip/Picture33.png";
import masonary10 from "./../../images/gallery/fieldTrip/Picture34.png";
import masonary11 from "./../../images/gallery/fieldTrip/Picture35.png";
import masonary12 from "./../../images/gallery/fieldTrip/Picture36.png";
import masonary13 from "./../../images/gallery/fieldTrip/Picture37.png";
import masonary14 from "./../../images/gallery/fieldTrip/Picture38.png";
import masonary15 from "./../../images/gallery/fieldTrip/Picture39.png";
import masonary16 from "./../../images/gallery/fieldTrip/Picture40.png";
import masonary17 from "./../../images/gallery/fieldTrip/Picture41.png";
import masonary18 from "./../../images/gallery/fieldTrip/Picture42.png";
// import masonary19 from "./../../images/gallery/fieldTrip/Picture43.png";
import masonary20 from "./../../images/gallery/fieldTrip/Picture44.png";
import masonary21 from "./../../images/gallery/fieldTrip/Picture45.png";
import masonary22 from "./../../images/gallery/fieldTrip/Picture46.png";
import masonary23 from "./../../images/gallery/fieldTrip/Picture47.png";
import masonary24 from "./../../images/gallery/fieldTrip/Picture48.png";
import masonary25 from "./../../images/gallery/fieldTrip/Picture49.png";
import masonary26 from "./../../images/gallery/fieldTrip/Picture50.png";
import masonary27 from "./../../images/gallery/fieldTrip/Picture51.png";
import masonary28 from "./../../images/gallery/fieldTrip/Picture52.png";
import masonary29 from "./../../images/gallery/fieldTrip/Picture53.jpeg";
import masonary30 from "./../../images/gallery/fieldTrip/Picture54.jpeg";
import masonary31 from "./../../images/gallery/fieldTrip/Picture55.jpeg";
import masonary32 from "./../../images/gallery/fieldTrip/Picture56.jpeg";

const galleryBlog = [
  // { images: masonary1 },
  { images: masonary2 },
  // { images: masonary3 },
  { images: masonary4 },
  { images: masonary5 },
  // { images: masonary6 },
  { images: masonary29 },
  { images: masonary7 },
  { images: masonary8 },
  { images: masonary9 },
  { images: masonary10 },
  { images: masonary11 },
  { images: masonary12 },
  { images: masonary13 },
  { images: masonary14 },
  { images: masonary21 },
  { images: masonary15 },
  { images: masonary16 },
  { images: masonary17 },
  { images: masonary18 },
  // { images: masonary19 },
  { images: masonary30 },
  { images: masonary20 },
  { images: masonary21 },
  { images: masonary22 },
  { images: masonary23 },
  { images: masonary31 },
  { images: masonary24 },
  { images: masonary25 },
  { images: masonary26 },
  { images: masonary27 },
  { images: masonary32 },
  { images: masonary28 },
];
// Masonry section
const masonryOptions = {
  transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };
// Masonry section end

//Light Gallery Icon
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link onClick={() => openLightbox(props.imageToOpen)} className="check-km">
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};

class GalleryMasonary1 extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="Field Trip" activeMenu="Field Trip" />
          <div className="content-block">
            <div className="section-full content-inner">
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="head-title text-secondry">Field Trip</h2>
                  {/* <p>
                    We provide three classes with nine to twenty children each
                    aged twelve months to six years of age.
                  </p> */}
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <div className="clearfix" id="lightgallery">
                      <ul
                        id="masonry"
                        className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery masonry-gallery"
                      >
                        <Masonry
                          className={"my-gallery-class"}
                          options={masonryOptions}
                          disableImagesLoaded={false}
                          updateOnEachImageLoad={false}
                          imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                          {galleryBlog.map((item, index) => (
                            <li
                              className="web design card-container col-lg-4 col-md-6 col-sm-6 "
                              key={index}
                            >
                              <div className="dlab-box frame-box m-b30">
                                <div className="dlab-thum dlab-img-overlay1 ">
                                  <img src={item.images} alt="" />
                                  <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <Iconimage />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </Masonry>
                      </ul>
                    </div>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default GalleryMasonary1;
