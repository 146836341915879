import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";
import Masonry from "react-masonry-component";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import AccordionBlog from "../Element/AccordionBlog";
import TestiMonialSlider from "../Element/TestiMonialSlider";
import VideoPopup from "../Element/VideoPopup";
//Images
import bnr1 from "./../../images/line2.png";
import bnr2 from "./../../images/background/bg5.jpg";
import bgimg2 from "./../../images/background/bg1.jpg";
import bnr4 from "./../../images/line2.png";
import about from "./../../images/about/about-1.jpg";
import gallery1 from "./../../images/gallery/aboutGallery/Picture01.png";
import gallery2 from "./../../images/gallery/aboutGallery/Picture02.png";
import gallery3 from "./../../images/gallery/aboutGallery/Picture03.png";
import gallery4 from "./../../images/gallery/aboutGallery/Picture04.png";
import gallery5 from "./../../images/gallery/aboutGallery/Picture05.png";
import gallery6 from "./../../images/gallery/aboutGallery/Picture06.png";
// import gallery7 from './../../images/gallery/img7.jpeg';
// import gallery8 from './../../images/gallery/img8.jpeg';
// import gallery9 from './../../images/gallery/img9.jpeg';
// import gallery10 from './../../images/gallery/img10.jpeg';
// import gallery11 from './../../images/gallery/img11.jpeg';
// import gallery12 from './../../images/gallery/img13.jpeg';

// Masonry section
const masonryOptions = {
  transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };
// Masonry section end

//Light Gallery Icon
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      to={"#"}
      onClick={(e) => {
        e.preventDefault();
        openLightbox(props.imageToOpen);
      }}
      className="check-km"
    >
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};
const galleryBlog = [
  { image: gallery1 },
  { image: gallery2 },
  { image: gallery3 },
  { image: gallery6 },
  { image: gallery4 },
  { image: gallery5 },

  // { image: gallery7 },
  // { image: gallery8 },
  // { image: gallery9 },
  // { image: gallery10 },
  // { image: gallery11 },
  // { image: gallery12 },
];

function BackgroundBlog() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"
            style={{
              visibility: "visible",
              animationDuration: "2s",
              animationDelay: "0.2s",
              animationName: "fadeIn",
            }}
          >
            <div className="section-head text-center">
              <div className="video-play">{/* <VideoPopup /> */}</div>
              <h2>
                Let Your Kids Have an Amazing
                <br />
                Time at Cradle to Stars
              </h2>
              {/* <Link to={"./contect-us"} className="btn btn-md radius-xl">Read More</Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function EducationBanner() {
  return (
    <>
      <div
        className="section-full bg-white content-inner-2 about-content bg-img-fix"
        style={{ backgroundImage: "url(" + bgimg2 + ")" }}
      >
        <div className="about-overlay-box"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="section-head">
                <h2 className="head-title text-yellow">
                  Education from birth
                  <br />
                  begins with us
                </h2>
                <p className="text-white">
                  Over the years, we have witnessed many little ones come
                  through our doors, starting from when they were just three
                  months old. It has been a joy to watch them grow, learn, and
                  develop into confident young individuals.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
          </div>
        </div>
      </div>
    </>
  );
}

class AboutUs1 extends Component {
  componentDidMount() {
    var splitBox = document.querySelectorAll(".split-box");

    var fSB = [].slice.call(splitBox);

    fSB.forEach((el) => el.classList.add("split-active"));
  }
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="About Us" activeMenu="About Us" />
          <div className="content-block">
            <div
              className="section-full bg-white content-inner-2 about-area"
              style={{
                backgroundImage: "url(" + bnr1 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 m-b15">
                    <div className="section-head">
                      <h2
                        className="head-title text-secondry"
                        style={{ color: "#eb018e" }}
                      >
                        About Us
                      </h2>
                      <p>
                        We are a team of passionate educators who have dedicated
                        our lives to nurturing young minds and helping them
                        grow. Our founder, Rekha, brings over 17 years of
                        experience in both corporate and education fields, and
                        her passion for teaching has led her to create this
                        wonderful learning environment.
                      </p>
                      <p>
                        At our school, we believe that every child is unique and
                        deserves individual attention and care. Our personal
                        touch approach ensures that each child feels valued,
                        heard, and understood. We strive to create a safe and
                        nurturing environment where children can learn, play,
                        and explore.
                      </p>
                      <p>
                        Over the years, we have witnessed many little ones come
                        through our doors, starting from when they were just
                        three months old. It has been a joy to watch them grow,
                        learn, and develop into confident young individuals. We
                        are proud to have played a part in their journey.
                      </p>
                      <p>
                        Our curriculum is designed to be holistic, emphasizing
                        not just academic excellence but also social and
                        emotional development. We believe that learning should
                        be fun, engaging, and meaningful. Our dedicated team of
                        teachers is passionate about helping children reach
                        their full potential and instilling a lifelong love of
                        learning.
                      </p>
                      <p>
                        We also believe in the importance of parent-teacher
                        partnership. We encourage open communication and
                        collaboration between parents and teachers to ensure
                        that we are working together to support each child's
                        individual needs.
                      </p>
                    </div>
                    {/* <AccordionBlog /> */}
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
                    <div className="split-box">
                      <div className="about-media">
                        <img src={about} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section-full bg-white content-inner-2 about-content bg-img-fix"
              style={{ backgroundImage: "url(" + bnr2 + ")" }}
            >
              <BackgroundBlog />
            </div>
            {/*  Portfolio  */}
            <div className="section-full content-inner">
              <div className="container">
                <div className="section-head text-center">
                  <h2
                    className="head-title text-secondry"
                    style={{ color: "#eb018e" }}
                  >
                    Gallery
                  </h2>
                  {/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <div className="clearfix" id="lightgallery">
                      <ul
                        id="masonry"
                        className="dlab-gallery-listing gallery-grid-4 gallery"
                      >
                        <Masonry
                          className={"my-gallery-class"}
                          options={masonryOptions}
                          disableImagesLoaded={false}
                          updateOnEachImageLoad={false}
                          imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                          {galleryBlog.map((data, index) => (
                            <li
                              className="web design card-container col-lg-4 col-md-6 col-sm-6 "
                              key={index}
                            >
                              <div className="dlab-box frame-box m-b30">
                                <div className="dlab-thum dlab-img-overlay1">
                                  <img src={data.image} alt="" />
                                  <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <Iconimage imageToOpen={index} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </Masonry>
                      </ul>
                    </div>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
            <EducationBanner />
            {/* <div className="section-full bg-white content-inner-2" style={{backgroundImage:"url(" + bnr4 +")", backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Testimonials about center</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TestiMonialSlider />
							</div>
						</div> */}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export { BackgroundBlog, EducationBanner };
export default AboutUs1;
