import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";
import Masonry from "react-masonry-component";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";

//images
import gallery1 from "./../../images/gallery/img1.jpeg";
import gallery2 from "./../../images/gallery/img2.jpeg";
import gallery3 from "./../../images/gallery/img3.jpeg";
import gallery4 from "./../../images/gallery/img4.jpeg";
import gallery5 from "./../../images/gallery/img5.jpeg";
import gallery6 from "./../../images/gallery/img6.jpeg";
import gallery7 from "./../../images/gallery/img7.jpeg";
import gallery8 from "./../../images/gallery/img8.jpeg";
import gallery9 from "./../../images/gallery/img9.jpeg";
import gallery10 from "./../../images/gallery/img10.jpeg";
import gallery11 from "./../../images/gallery/img11.jpeg";
import gallery12 from "./../../images/gallery/img13.jpeg";

const galleryBlog = [
  { images: gallery1 },
  { images: gallery2 },
  { images: gallery3 },
  { images: gallery4 },
  { images: gallery5 },
  { images: gallery6 },
  { images: gallery7 },
  { images: gallery8 },
  { images: gallery9 },
  { images: gallery10 },
  { images: gallery11 },
  { images: gallery12 },
];
// Masonry section
const masonryOptions = {
  transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };
// Masonry section end

//Light Gallery Icon
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      to={"#"}
      onClick={(e) => {
        e.preventDefault();
        openLightbox(props.imageToOpen);
      }}
      className="check-km"
    >
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};

class Gallery extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="Gallery" activeMenu="Gallery" />
          <div className="content-block">
            <div className="section-full content-inner">
              <div className="container">
                <div className="section-head text-center">
                  <h2
                    className="head-title text-secondry"
                    style={{ color: "#eb018e" }}
                  >
                    Gallery
                  </h2>
                  {/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <div className="clearfix" id="lightgallery">
                      <ul
                        id="masonry"
                        className="dlab-gallery-listing gallery-grid-4 gallery"
                      >
                        <Masonry
                          className={"my-gallery-class"}
                          options={masonryOptions}
                          disableImagesLoaded={false}
                          updateOnEachImageLoad={false}
                          imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                          {false &&
                            galleryBlog.map((item, index) => (
                              <li
                                className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn"
                                key={index}
                              >
                                <div className="dlab-box frame-box m-b30">
                                  <div className="dlab-thum dlab-img-overlay1 ">
                                    <img src={item.images} alt="" />
                                    <div className="overlay-bx">
                                      <div className="overlay-icon">
                                        <Iconimage imageToOpen={index} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </Masonry>
                      </ul>
                    </div>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default Gallery;
