import React, { Component } from "react";
import { Link } from "react-router-dom";
import bnr1 from "./../../images/main-slider/slide10.png";
import bnr2 from "./../../images/main-slider/slide5.png";
import bnr3 from "./../../images/main-slider/slide6.png";

import bnr7 from "./../../images/main-slider/slide7.jpg";
import bnr8 from "./../../images/main-slider/slide8.jpg";
import bnr9 from "./../../images/main-slider/slide9.jpg";

import Carousel from "react-bootstrap/Carousel";

const BannerSlider = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const carouselBlog = [
    { image: isMobile ? bnr7 : bnr1 },
    { image: isMobile ? bnr8 : bnr2 },
    { image: isMobile ? bnr9 : bnr3 },
  ];

  return (
    <Carousel
      indicators={false}
      controls={true}
      className="owl-slider owl-carousel owl-theme owl-btn-center-lr dots-none"
      autoplay={true}
    >
      {carouselBlog.map((item, index) => (
        <Carousel.Item key={index}>
          <div className="slide-item">
            <div className="slide-item-img">
              <img src={item.image} alt="" />
            </div>
            <div className="slide-content">
              <div className="slide-content-box container">
                {/* <div className="slide-content-area">
										<h2 className="slider-title">Children Academy <span>First ReseaArchers</span></h2>
										<p>Children's Academy will provide a stimulating and safe environment for children ages three month</p>
										<Link to={"./contect-us"} className="btn btn-md kids-btn radius-xl">Join us</Link>
									</div> */}
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default BannerSlider;
