import React,{Component} from 'react';
import Slider from "react-slick";
import grid1 from './../../images/testimonials/pic4.jpg';


const latestBlog = [
	{
		image: grid1,
		name: "Chandu Sirigiri",
		desc: "Friendly staff & nice care taking towards kids, Am happy to send my kid to planet kids school. Initially my kid was reluctant to go play school, later he was moving to school happily. This shows my kid's comfortableness with staff. Honestly speaking, After joining play school, I can see lot of change in my kid."
	},
	{
		image: grid1,
		name: "Deepthy R",
		desc:"Awesome place for kids, friendly and professional teachers.. my son has developed good vocabulary in just 2 months time and he loves going to his playschool.. 😊"
	},
	{
		image: grid1,
		name: "Ranjita Jayasimha",
		desc: "Really very good place for kids, feels like home ... my daughter started eating properly after going Der and learning many new things ...without tension we an leave our kids here"
	},
	{
		image: grid1,
		name: "Manjula",
		desc: "Very good place! Safe & clean with great staff and very welcoming. My 4 year old kid have been going here for 8 months and I couldn’t ask for a better daycare. Please keep on the great job that you do."
	},
	{
		image: grid1,
		name: "Ashish Sharma",
		desc: "Really the best preschool and daycare for your kid. Rekha Mam n all other staff are nice and takes care of kids like their own. Very happy to send my son there."
	},
	
]
class TestiMonialSlider extends Component{	
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 1,			
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<>	
				<div className="sprite-nav">
					<Slider className="client-carousel owl-carousel owl-theme  " ref={c => (this.slider = c)} {...settings}>
						{latestBlog.map((item, index) => (
							<div className="item" key={index}>
								<div className="client-box">
									<div className="testimonial-detail clearfix">
										<div className="testimonial-pic radius">
											<img src={item.image} width="100" height="100" alt="" />
										</div>
										<h5 className="testimonial-name m-t0 m-b5" style={{ color: "#eb018e" }}>{item.name}</h5> 
									</div>
									<div className="testimonial-text">
										<p>{item.desc}</p>
									</div>
								</div>	
							</div>
						))}	
					</Slider>		
					<div className="owl-nav" style={{ textAlign: "center" }}>
						<div className=" owl-prev " onClick={this.previous}/>
						<div className="owl-next " onClick={this.next}/>
					</div>
				</div>	
			</>	
		)
	}
}
export default TestiMonialSlider;