import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import bnr1 from './../../images/line2.png';
import team1 from './../../images/team/pic9.jpg';

const teamBlog = [
	{image: team1 ,}
];

class Teachers extends Component{
	render(){
		return(
			<Fragment>
				<Header />
					<div className="page-content">
						<PageTitle motherMenu="Teachers" activeMenu="Teachers" />
						<div className="content-block">
							<div className="section-full bg-white content-inner" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
								<div className="container">
									<div className="section-head text-center">
									<h2 className="head-title text-secondry" style={{ color: "#eb018e" }}>About the Teachers</h2>
										{/* <p>
											We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs
										</p> */}
									</div>
								
								<div className="section-head text-center">
									<p>Dedicated, Passionate and well trained teachers who loves working with kids with almost care and safety. Our teachers ensure every kid learns in its own way and get the best out of the  school and make the best memories</p>
								</div>
								<div className="text-center">
									<img src={team1} alt="" />
								</div>
								</div>
							</div>
						</div>
					</div>
				<Footer />
			</Fragment>
		)
	}
}

export default Teachers;